import request from '@/utils/request'

// 验证用户名是否存在
export function checkUser(reqParams) {
  return request({
    url: '/user/check-user',
    method: 'post',
    data: reqParams
  })
}

// 获取验证码
export function getCode(reqParams) {
  return request({
    url: `/user/get/verification-code`,
    method: 'post',
    data: reqParams
  })
}

// 效验验证码
export function checkCode(reqParams) {
  return request({
    url: `/user/check/verification-code`,
    method: 'post',
    data: reqParams
  })
}

// 修改密码
export function updatePassword(reqParams) {
  return request({
    url: `/user/update/edit-password`,
    method: 'put',
    data: reqParams
  })
}
