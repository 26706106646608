<template>
  <div class="login-container">
    <el-form
      :model="ruleForm2"
      :rules="rules2"
      ref="ruleForm2"
      class="login-form"
      auto-complete="on"
      label-position="left"
    >
      <h3 class="title">找回密码</h3>
      <el-form-item prop="username" :error="errorMsg">
        <span class="svg-container">
          <svg-icon icon-class="user"/>
        </span>
        <el-input
          type="text"
          v-model.trim="ruleForm2.username"
          autocomplete="off"
          placeholder="请输入您的用户名"
          @blur="checkUsername()"
        ></el-input>
      </el-form-item>
      <el-form-item prop="code" :error="codeErrorMsg">
        <span class="svg-container">
          <svg-icon icon-class="code"/>
        </span>
        <el-input
          type="text"
          v-model="ruleForm2.code"
          readonly @focus="handleFocus($event)"
          placeholder="请输入邮箱验证码"
          @blur="checkRandom()"
        ></el-input>
        <el-button
          class="btnStyle"
          type="primary"
          @click="codeTime()"
          :disabled="codeDisabled"
        >{{codeMsg}}</el-button>
        <div class="showInfoClass">
          <p>{{showInfo.name}}</p>
          <p>{{showInfo.email}}</p>
        </div>
      </el-form-item>

      <!-- 密码 -->
      <el-form-item prop="pass">
        <span class="svg-container">
          <svg-icon icon-class="password"/>
        </span>
        <el-input :type="pwdType" v-model.trim="ruleForm2.pass" readonly @focus="handleFocus($event)" placeholder="请输入新密码"/>
      </el-form-item>

      <el-form-item prop="checkPass">
        <span class="svg-container">
          <svg-icon icon-class="password"/>
        </span>
        <el-input
          :type="pwdType"
          v-model.trim="ruleForm2.checkPass"
          readonly @focus="handleFocus($event)"
          autocomplete="off"
          placeholder="请再次输入新密码"
        />
      </el-form-item>
      <el-form-item>
        <el-button
          :loading="loading"
          type="primary"
          style="width:100%;"
          @click.native.prevent="submitForm()"
        >提交</el-button>
      </el-form-item>
      <el-form-item>
        <el-button style="width:100%;" @click="resetForm('ruleForm2')">重置</el-button>
      </el-form-item>
      <span>
        温馨提示 : 如您没有邮箱请联系管理员重置密码 。
        <el-button type="text" size="small" @click="$router.push({path: 'login'})">返回</el-button>
      </span>
    </el-form>
  </div>
</template>

<script>
import {
  checkUser,
  getCode,
  checkCode,
  updatePassword
} from '@/api/findPassword'

export default {
  name: 'Login',
  data() {
    // 验证校验码 ^[0-9]*$
    var validateCode = (rule, value, callback) => {
      if (value) {
        if (this.ruleForm2.code) {
          var reg = /^[0-9]{6}$/
          // alert(reg.test(value), value)
          if (!reg.test(value)) {
            callback(new Error('验证码只能为数字,请正确的验证码'))
          }
        }
        callback()
      } else {
        callback(new Error('请输入邮箱验证码'))
      }
    }

    // 效验密码
    // var validatePass2 = (rule, value, callback) => {
    //   if (value === '') {
    //     callback(new Error('请输入密码'))
    //   } else {
    //     if (this.ruleForm2.checkPass !== '') {
    //       this.$refs.ruleForm2.validateField('checkPass')
    //     }
    //     callback()
    //   }
    // }

    var validatePass2 = (rule, value, callback) => {
      const reg = /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?!([^(0-9a-zA-Z)])+$).{8,20}$/
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (!reg.test(value)) {
        callback(new Error('密码必须包含字母和数字，且不小于8位'))
      } else if (value !== this.ruleForm2.pass) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      ruleForm2: {
        pass: '',
        checkPass: '',
        username: '',
        password: '',
        code: '',
        email: '',
        uuId: '',
        userId: ''
      },
      errorMsg: '', // 用户名错误信息
      codeErrorMsg: '', // 验证码错误信息
      code: '',
      rules2: {
        username: [
          { required: true, trigger: 'blur', message: '用户名不能为空哦' }
        ],
        pass: [{ required: true, validator: validatePass2, trigger: 'blur' }],
        checkPass: [
          { required: true, validator: validatePass2, trigger: 'blur' }
        ],
        code: [{ required: true, validator: validateCode, trigger: 'blur' }]
      },
      uuId: '',
      loading: false,
      pwdType: 'password',
      redirect: undefined,
      // 是否禁用按钮
      codeDisabled: false,
      // 倒计时秒数
      countdown: 120,
      // 按钮上的文字
      codeMsg: '获取邮箱验证码',
      // 定时器
      timer: null,
      showInfo: {}
    }
  },
  watch: {
    $route: {
      handler: function(route) {
        if (route.query && route.query.redirect) {
          let url = route.query.redirect
          Object.keys(route.query).forEach(key => {
            if (key !== 'redirect') {
              url += '&' + key + '=' + route.query[key]
            }
          })
          this.redirect = url
        }
        this.ruleForm2.username = this.$route.params.name
      },
      immediate: true
    }
  },
  methods: {
    // showPwd() {
    //   if (this.pwdType === 'password') {
    //     this.pwdType = ''
    //   } else {
    //     this.pwdType = 'password'
    //   }
    // },
    // 验证用户名是否存在
    checkUsername() {
      this.showInfo = {}
      if (this.ruleForm2.username) {
        this.errorMsg = ''
        const params = { username: this.ruleForm2.username }
        checkUser(params)
          .then(response => {
            if (response.data.isOldUser === false) {
              this.errorMsg = ''
            }
          })
          .catch(error => {
            console.log(error)
            this.errorMsg = '用户错误'
          })
      } else {
        console.log('请输入用户名')
      }
    },
    // 获取验证码
    codeTime() {
      if (!this.ruleForm2.username) {
        // 如果用户名空 警告
        this.$message({
          message: '请输入用户名~',
          type: 'warning'
        })
        return false
      } else if (this.errorMsg === '用户错误') {
        return false
      } else {
        // 验证码120秒倒计时
        if (!this.timer) {
          const params = { username: this.ruleForm2.username, type: 1 }
          // 获取验证码
          getCode(params)
            .then(response => {
              if (response.data.email.includes('@')) {
                this.showInfo = response.data
              }
              this.$message({
                message: '发送成功,请打开邮箱查看验证码~',
                type: 'success'
              })
            })
            .catch(error => {
              console.log(error)
            })
          this.timer = setInterval(() => {
            if (this.countdown > 0 && this.countdown <= 120) {
              this.codeDisabled = true
              this.countdown--
              if (this.countdown !== 0) {
                this.codeMsg = '重新发送(' + this.countdown + ')'
              } else {
                clearInterval(this.timer)
                this.codeMsg = '获取邮箱验证码'
                this.countdown = 120
                this.timer = null
                this.codeDisabled = false
              }
            }
          }, 1000)
        }
      }
    },

    // 效验验证码
    checkRandom() {
      if (this.ruleForm2.code && this.ruleForm2.username) {
        const params = {
          random: this.ruleForm2.code,
          type: 1,
          username: this.ruleForm2.username
        }
        this.codeErrorMsg = ''
        checkCode(params)
          .then(response => {
            this.codeErrorMsg = '效验正确'
            this.ruleForm2.uuId = response.data
          })
          .catch(error => {
            console.log(error)
          })
      }
    },

    // 提交
    submitForm() {
      if (this.codeErrorMsg !== '效验正确') {
        this.$message({
          message: '验证码不正确',
          type: 'warning'
        })
        return false
      } else {
        this.$refs.ruleForm2.validate(valid => {
          if (valid) {
            const params = {
              newPassword: this.ruleForm2.pass,
              type: 1,
              username: this.ruleForm2.username,
              uuid: this.ruleForm2.uuId
            }
            updatePassword(params)
              .then(response => {
                this.$message({
                  message: '修改成功~',
                  type: 'success'
                })
                this.$router.push({ path: '/login' })
              })
              .catch(error => {
                console.log(error)
              })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    handleFocus(e) {
      e.target.readOnly = false
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss">
$bg: #2d3a4b;
$light_gray: #eee;

/* reset element-ui css */
.login-container {
  .el-input {
    display: inline-block;
    height: 47px;
    width: 85%;
    input {
      background: transparent;
      border: 0px;
      -webkit-appearance: none;
      border-radius: 0px;
      padding: 12px 5px 12px 15px;
      color: $light_gray;
      height: 47px;
      &:-webkit-autofill {
        -webkit-box-shadow0: 0 0 0px 1000px $bg inset !important;
        -webkit-text-fill-color: #fff !important;
      }
    }
  }
  .el-form-item {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    color: #454545;
  }
}
</style>

<style rel="stylesheet/scss" lang="scss" scoped>
$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #eee;
.login-container {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: $bg;
  .login-form {
    position: absolute;
    left: 0;
    right: 0;
    width: 520px;
    max-width: 100%;
    padding: 35px 35px 15px 35px;
    margin: 120px auto;
  }
  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;
    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }
  .svg-container {
    padding: 6px 5px 6px 15px;
    color: $dark_gray;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
  }
  .title {
    font-size: 26px;
    font-weight: 400;
    color: $light_gray;
    margin: 0px auto 40px auto;
    text-align: center;
    font-weight: bold;
  }
  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }
  .btnStyle {
    position: absolute;
    right: 5px;
    top: 5px;
    padding: 13px 17px;
  }
  .showInfoClass{
    position: absolute;
    top:-10px;
    left:460px;
    color:rgb(172, 63, 63);
    p{
      line-height: 14px;
    }
  }
}
</style>
